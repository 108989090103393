import { createMuiTheme } from "@material-ui/core/styles";
import { lightBlue, grey } from "@material-ui/core/colors";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: lightBlue.A700,
    },
    secondary: {
      main: lightBlue.A100,
    },
  },
  overrides: {
    MUIDataTableToolbar: {
      root: {
        backgroundColor: grey["900"],
      },
    },
    MUIDataTableBodyCell: {
      root: {
        backgroundColor: grey["600"],
      },
    },
    MUIDataTableSelectCell: {
      root: {
        backgroundColor: grey["600"],
      },
    },
  },
});

export default theme;
