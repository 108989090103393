import * as Yup from "yup";

const UnitSchema = Yup.object({
  unit: Yup.object({
    status: Yup.string().required("Required"),
    name: Yup.string().required("Required"),
    serialNumber: Yup.string().required("Required"),
    inventoryNumber: Yup.string().required("Required"),
    requirementNumber: Yup.string().required("Required"),
    comment: Yup.string().required("Required"),
  }),
  location: Yup.object({
    locationID: Yup.number(),
    name: Yup.string(),
  })
    .nullable()
    .required("Location is required"),
  country: Yup.object({
    originID: Yup.number(),
    name: Yup.string(),
  })
    .nullable()
    .required("Origin country is required"),
});

export default UnitSchema;
