import { useState, useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Field } from "formik";

import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from "formik-material-ui-lab";

interface AutoCompleteProps<T> {
  name: string;
  label: string;
  value?: T | T[];
  multiple: boolean;
  readOnly?: boolean;
  getOptions: () => Promise<T[]>;
  optionsFilter?: (option: T) => boolean;
  getOptionSelected: (option: T, value: T) => boolean;
  getOptionLabel: (option: T) => string;
  validate?: (value: T) => any;
  touched: any;
  errors: any;
}

const AutoComplete = <T,>({
  name,
  label,
  value,
  multiple,
  readOnly,
  getOptions,
  optionsFilter,
  getOptionSelected,
  getOptionLabel,
  validate,
  touched,
  errors,
}: AutoCompleteProps<T>) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let active = true;

    if (!open) {
      return undefined;
    }

    (async () => {
      setLoading(true);
      const options = (await getOptions()).filter(
        optionsFilter ? optionsFilter : () => true
      );
      if (active) {
        setOptions(options);
      }
      setLoading(false);
    })();

    return () => {
      active = false;
    };
  }, [open, getOptions, optionsFilter]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Field
      name={name}
      component={Autocomplete}
      // Just like other fields.
      style={{ width: 196.667 }}
      open={open && !readOnly}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      defaultValue={value}
      options={options}
      multiple={multiple}
      filterSelectedOptions={multiple}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      loading={loading}
      validate={validate}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          label={label}
          error={touched[name] && !!errors[name]}
          helperText={touched[name] && errors[name]}
          InputProps={{
            ...params.InputProps,
            readOnly: readOnly,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoComplete;
