import { createMuiTheme } from "@material-ui/core/styles";
import { blue, pink, red, grey } from "@material-ui/core/colors";

export const blueTheme = createMuiTheme({
  palette: { primary: { main: blue[200] } },
});
export const pinkTheme = createMuiTheme({
  palette: { primary: { main: pink[200] } },
});
export const redTheme = createMuiTheme({
  palette: { primary: { main: red[500] } },
});
export const whiteTheme = createMuiTheme({
  palette: { primary: { main: grey[50] } },
});
