import ReactDOM from "react-dom";

import { RecoilRoot } from "recoil";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

import { SnackbarProvider } from "notistack";
import { CloseSnackbarAction } from "./helpers/renderers";

import App from "./App";

ReactDOM.render(
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SnackbarProvider maxSnack={3} action={CloseSnackbarAction}>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </RecoilRoot>,
  document.querySelector("#root")
);
