import { useState, ReactNode } from "react";

import { logout } from "../../api/auth";

import clsx from "clsx";

import { useRecoilState } from "recoil";
import { loadingState } from "../../state/loading";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
// import Fab from "@material-ui/core/Fab";
import LinearProgress from "@material-ui/core/LinearProgress";

import Menu from "@material-ui/icons/Menu";
import ExitToApp from "@material-ui/icons/ExitToApp";
// import Add from "@material-ui/icons/Add";

import DrawerContent from "../../components/DrawerContent";

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      flexGrow: 1,
      display: "block",
    },
    logoutButton: {
      [theme.breakpoints.down("xs")]: {
        marginRight: "-12px",
      },
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
    },
    contentShift: {
      [theme.breakpoints.up("sm")]: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
      },
    },
    fab: {
      zIndex: theme.zIndex.drawer + 1,
      position: "absolute",
      top: theme.spacing(5.5),
      left: theme.spacing(2),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  })
);

interface DefaultLayoutProps {
  children: ReactNode;
}

const DefaultLayout = ({ children }: DefaultLayoutProps) => {
  const classes = useStyles();

  const [loading] = useRecoilState(loadingState);

  const [openDrawer, setOpenDrawer] = useState(true);
  const [openMobileDrawer, setOpenMobileDrawer] = useState(false);

  const handleDrawer = () => {
    setOpenDrawer(!openDrawer);
    if (!openMobileDrawer) {
      setOpenMobileDrawer(true);
    }
  };
  const handleMobileDrawer = () => {
    setOpenMobileDrawer(!openMobileDrawer);
  };

  const logOut = async () => {
    await logout();
    window.location.replace("/login");
  };

  return (
    <>
      <CssBaseline />
      <AppBar color="default" position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
          >
            <Menu />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            License
          </Typography>
          <Hidden xsDown implementation="js">
            <Button
              variant="contained"
              color="primary"
              className={classes.logoutButton}
              endIcon={<ExitToApp />}
              onClick={logOut}
            >
              Log out
            </Button>
          </Hidden>
          <Hidden smUp implementation="js">
            <IconButton
              color="primary"
              className={classes.logoutButton}
              onClick={logOut}
            >
              <ExitToApp />
            </IconButton>
          </Hidden>
        </Toolbar>
        <LinearProgress
          variant={
            loading.variant as
              | "determinate"
              | "indeterminate"
              | "buffer"
              | "query"
              | undefined
          }
          value={loading.value}
          style={{ display: loading.display ? "block" : "none" }}
        />
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden xsDown implementation="js">
          <Drawer
            variant="persistent"
            open={openDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <DrawerContent closeDrawer={handleMobileDrawer} />
          </Drawer>
        </Hidden>
        <Hidden smUp implementation="js">
          <Drawer
            variant="temporary"
            open={openMobileDrawer}
            onClose={handleMobileDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <DrawerContent closeDrawer={handleMobileDrawer} />
          </Drawer>
        </Hidden>
      </nav>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: openDrawer,
        })}
      >
        <Toolbar />
        {children}
      </main>
    </>
  );
};

export default DefaultLayout;
