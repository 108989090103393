import { useState, MouseEvent } from "react";
import { Redirect } from "react-router-dom";

import { useRecoilState, useRecoilValue } from "recoil";
import { userState, loggedInState } from "../state/auth";

import { login } from "../api/auth";

import { makeStyles, Theme } from "@material-ui/core/styles";

import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import LockOutlined from "@material-ui/icons/LockOutlined";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";

import { useSnackbar } from "notistack";
import { CloseSnackbarAction } from "../helpers/renderers";

import LoginSchema from "../validations/LoginSchema";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  avatarProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: 68,
    zIndex: 1,
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

interface User {
  username: string;
  password: string;
  rememberMe: boolean;
}

const Login = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState(true);

  const setUser = useRecoilState(userState)[1];
  const loggedIn = useRecoilValue(loggedInState);

  const { enqueueSnackbar } = useSnackbar();

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        {loading && (
          <CircularProgress size={48} className={classes.avatarProgress} />
        )}
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={{
            username: "",
            password: "",
            rememberMe: false,
          }}
          validationSchema={LoginSchema}
          onSubmit={async (u: User, { setSubmitting }) => {
            setLoading(true);
            try {
              await login(u.username, u.password, u.rememberMe);

              const token = localStorage.getItem("token");
              setUser({
                username: u.username,
                logoutAt: !!token
                  ? JSON.parse(
                      Buffer.from(token.split(".")[1], "base64").toString()
                    ).exp
                  : -1,
              });

              window.location.replace("/");
            } catch (err) {
              enqueueSnackbar("Invalid username/password", {
                variant: "error",
                action: CloseSnackbarAction,
              });
            } finally {
              setSubmitting(false);
              setLoading(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className={classes.form} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    name="username"
                    label="Username"
                    type="text"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    variant="outlined"
                    name="password"
                    label="Password"
                    type={hidden ? "password" : "text"}
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setHidden(!hidden)}
                            onMouseDown={(
                              event: MouseEvent<HTMLButtonElement>
                            ) => {
                              event.preventDefault();
                            }}
                          >
                            {hidden ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="rememberMe"
                        value="remember"
                        color="primary"
                      />
                    }
                    label="Remember me"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};

export default Login;
