import { atom } from "recoil";

export const loadingState = atom({
  key: "loadingState",
  default: {
    display: false,
    variant: "indeterminate",
    value: 0,
  },
});
