import { AxiosResponse } from "axios";

import api from ".";

import { APIResponse } from "../types";
import { Unit, UnitResponse } from "../types/unit";

export const getAllUnits = async (
  page: number,
  size: number,
  search?: string
) => {
  const resp = await api.get<UnitResponse, AxiosResponse<UnitResponse>>(
    `/units?page=${page}&pageSize=${size}${search ? `&search=${search}` : ""}`
  );
  return resp.data;
};

export const getUnit = async (id: string) => {
  const resp = await api.get<Unit, AxiosResponse<Unit>>(`/units/${id}`);
  return resp.data;
};

export const addUnit = async (u: Unit) => {
  await api.post("/units", {
    locationID: u.locationID,
    originCountryID: u.originCountryID,
    status: u.status,
    name: u.name,
    serialNumber: u.serialNumber,
    inventoryNumber: u.inventoryNumber,
    requirementNumber: u.requirementNumber,
    comment: u.comment,
  });
};

export const editUnit = async (u: Unit) => {
  await api.put("/units", {
    unitID: u.unitID,
    locationID: u.locationID,
    originCountryID: u.originCountryID,
    status: u.status,
    name: u.name,
    serialNumber: u.serialNumber,
    inventoryNumber: u.inventoryNumber,
    requirementNumber: u.requirementNumber,
    comment: u.comment,
  });
};

export const debitUnit = async (id: number | string) => {
  await api.patch(`/units/${id}/debit`);
};

export const deleteUnit = async (id: number | string) => {
  await api.delete(`/units/${id}`);
};

export const getLocations = async () => {
  const resp = await api.get<
    APIResponse<{ locationID: number; name: string }>,
    AxiosResponse<APIResponse<{ locationID: number; name: string }>>
  >("/units/locations?page=1&pageSize=500");
  return resp.data.data;
};

export const getUnitLocation = async (locationID: number | string) => {
  const resp = await getLocations();
  return resp.filter((loc) => loc.locationID === locationID)[0];
};

export const getCountries = async () => {
  const resp = await api.get<
    APIResponse<{ originID: number; name: string }>,
    AxiosResponse<APIResponse<{ originID: number; name: string }>>
  >("/units/origins?page=1&pageSize=500");
  return resp.data.data;
};

export const getUnitOrigin = async (originID: number | string) => {
  const resp = await getCountries();
  return resp.filter((or) => or.originID === originID)[0];
};
