export const getAuthToken = () => {
  const token = localStorage.getItem("token");

  return token ? token : "";
};

export const getCSRFToken = () => {
  const token = document
    .getElementsByTagName("body")[0]
    .getAttribute("csrf-token-value");

  return token ? token : "";
};

export const isoToHumanString = (val: string) => {
  const date = new Date(val);
  return !isNaN(date.getTime()) ? date.toLocaleString() : val;
};
