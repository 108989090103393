import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";

import { useRecoilValue } from "recoil";
import { loggedInState } from "../state/auth";

import DefaultLayout from "../views/layouts/Default";

import Login from "../views/Login";
import NotFound from "../views/NotFound";

import Arrivals from "../views/tables/Arrivals";
import Soft from "../views/tables/Software";
import Units from "../views/tables/Units";

import Departments from "../views/tables/Departments";

import UnitForm from "../views/forms/UnitForm";

interface AuthRouteProps extends RouteProps {
  component?: any;
  render?: any;
}

const AuthRoute = ({
  component: Component,
  render: renderer,
  ...rest
}: AuthRouteProps) => {
  const loggedIn = useRecoilValue(loggedInState);

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        loggedIn ? (
          Component ? (
            <Component {...routeProps} />
          ) : renderer ? (
            renderer()
          ) : (
            <div></div>
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: routeProps.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  return (
    <Router>
      <Switch>
        {/* <Redirect exact from="/" to="/" /> */}
        <Route exact path="/login" component={Login} />

        <Route>
          <DefaultLayout>
            <Switch>
              {/* Routes here */}
              <AuthRoute exact path="/arrival/all" component={Arrivals} />
              <AuthRoute exact path="/software/all" component={Soft} />
              <AuthRoute exact path="/unit/all" component={Units} />

              <AuthRoute exact path="/department/all" component={Departments} />

              <AuthRoute
                exact
                path="/unit/new"
                render={() => <UnitForm action="add" />}
              />
              <AuthRoute
                exact
                path="/unit/edit/:id"
                render={() => <UnitForm action="edit" />}
              />
              <AuthRoute
                exact
                path="/unit/delete/:id"
                render={() => <UnitForm action="delete" />}
              />

              <AuthRoute component={NotFound} />
            </Switch>
          </DefaultLayout>
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;
