import DataTable from "../../components/DataTable";

import { Department, departmentColumns } from "../../types/department";
import { getDepartments } from "../../api/departments";

const Departments = () => {
  return (
    <DataTable<Department>
      title="Arrivals"
      expandable={false}
      cols={departmentColumns}
      getData={getDepartments}
    />
  );
};

export default Departments;
