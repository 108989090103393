import api from ".";

import { getAuthToken, getCSRFToken } from "../helpers";

export const login = async (
  username: string,
  password: string,
  rememberMe: boolean
) => {
  const resp = await api.post("/signin", {
    identifier: username,
    password,
    rememberMe,
  });
  if (resp.data.token) {
    localStorage.setItem("token", resp.data.token);
    const authToken = getAuthToken();
    const csrfToken = getCSRFToken();
    api.defaults.headers = {
      "X-Auth-Token": authToken,
      "Csrf-Token": csrfToken,
    };
  }
};

export const logout = async () => {
  localStorage.removeItem("token");
  api.defaults.headers = undefined;
  await api.post("/signout");
};
