import DataTable from "../../components/DataTable";

import { Arrival, arrivalColumns } from "../../types/arrival";
import { getArrivals } from "../../api/arrivals";

const Arrivals = () => {
  return (
    <DataTable<Arrival>
      title="Arrivals"
      expandable={false}
      cols={arrivalColumns}
      getData={getArrivals}
    />
  );
};

export default Arrivals;
