import { MUIDataTableColumn } from "mui-datatables";

import { APIResponse } from ".";

import { softActionsRenderer } from "../helpers/renderers";

export type Soft = {
  softID: number;
  producer: string;
  producerID: number;
  product: string;
  productID: number;
  version: string;
  versionID: number;
};

export type SoftResponse = APIResponse<Soft>;

export const softColumns: MUIDataTableColumn[] = [
  { name: "softID", label: "ID" },
  { name: "producer", label: "Producer" },
  { name: "product", label: "Product" },
  { name: "version", label: "Version" },
  {
    name: "softID", // we need to pass id.
    label: "Actions",
    options: {
      customBodyRender: softActionsRenderer,
      sort: false,
    },
  },
];
