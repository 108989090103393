import { ReactNode } from "react";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minWidth: 275,
    },
    toolbar: {
      backgroundColor: "#212121",
    },
    form: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
        width: "25ch",
      },
    },
    cardActions: {
      padding: "8px 24px 24px",
    },
  })
);

export interface FormProps {
  action: "add" | "view" | "edit" | "delete";
}

interface FormLayoutProps {
  title: string;
  children: ReactNode;
}

const FormLayout = function ({ title, children }: FormLayoutProps) {
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6">{title}</Typography>
      </Toolbar>
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default FormLayout;
