import axios from "axios";

import { getAuthToken, getCSRFToken } from "../helpers";
import { logout } from "./auth";

const headers = () => {
  const authToken = getAuthToken();
  const csrfToken = getCSRFToken();
  return {
    "X-Auth-Token": authToken,
    "Csrf-Token": csrfToken,
  };
};

const api = axios.create({
  baseURL: "",
  responseType: "json",
  headers: headers(),
});

api.interceptors.response.use(
  (resp) => {
    return resp;
  },
  async (err) => {
    if (err.response.status === 401) {
      await logout();
      window.location.replace("/login");
    } else {
      return Promise.reject(err);
    }
  }
);

export default api;
