import { MUIDataTableColumn } from "mui-datatables";

import { APIResponse } from ".";

import { datetimeRenderer, arrivalActionsRenderer } from "../helpers/renderers";

export type Arrival = {
  arrivalID: number;
  contractor: string;
  contractorID: number;
  count: number;
  date: string;
  license: string;
  producer: string;
  product: string;
  softID: number;
  version: string;
};

export type ArrivalResponse = APIResponse<Arrival>;

export const arrivalColumns: MUIDataTableColumn[] = [
  { name: "arrivalID", label: "ID" },
  { name: "contractor", label: "Contractor" },
  { name: "count", label: "Count" },
  {
    name: "date",
    label: "Date",
    options: {
      customBodyRender: datetimeRenderer,
    },
  },
  { name: "license", label: "License" },
  { name: "producer", label: "Producer" },
  { name: "product", label: "Product" },
  { name: "version", label: "Version" },
  {
    name: "arrivalID", // we need to pass id.
    label: "Actions",
    options: {
      customBodyRender: arrivalActionsRenderer,
      sort: false,
    },
  },
];
