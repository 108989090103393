import { Link } from "react-router-dom";

import { useSnackbar, SnackbarKey } from "notistack";

import { MuiThemeProvider } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import Check from "@material-ui/icons/Check";
import NotInterested from "@material-ui/icons/NotInterested";
import Close from "@material-ui/icons/Close";

import { blueTheme, pinkTheme, redTheme } from "../common/themes";

import { isoToHumanString } from ".";

const actionsRender = (entity: string, id: string | undefined) => {
  return id ? (
    <>
      <MuiThemeProvider theme={blueTheme}>
        <Link
          to={`/${entity}/${id}`}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <Tooltip title="View">
            <IconButton style={{ padding: "5px" }} color="primary" size="small">
              <Visibility />
            </IconButton>
          </Tooltip>
        </Link>
      </MuiThemeProvider>
      <MuiThemeProvider theme={pinkTheme}>
        <Link
          to={`/${entity}/edit/${id}`}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <Tooltip title="Edit">
            <IconButton style={{ padding: "5px" }} color="primary" size="small">
              <Edit />
            </IconButton>
          </Tooltip>
        </Link>
      </MuiThemeProvider>
      <MuiThemeProvider theme={redTheme}>
        <Link
          to={`/${entity}/delete/${id}`}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <Tooltip title="Delete">
            <IconButton style={{ padding: "5px" }} color="primary" size="small">
              <Delete />
            </IconButton>
          </Tooltip>
        </Link>
      </MuiThemeProvider>
    </>
  ) : (
    ""
  );
};

export const arrivalActionsRenderer = (id: string | undefined) => {
  return actionsRender("arrival", id);
};

export const softActionsRenderer = (id: string | undefined) => {
  return actionsRender("soft", id);
};

export const departmentActionsRenderer = (id: string | undefined) => {
  return actionsRender("department", id);
};

export const unitActionsRenderer = (id: string | undefined) => {
  return actionsRender("unit", id);
};

export const boolValRenderer = (val: boolean | undefined) => {
  return val !== undefined ? val ? <Check /> : <NotInterested /> : "";
};

export const emptyStrRenderer = (str: string | undefined) => {
  return str !== undefined ? str === "" ? <NotInterested /> : str : "";
};

export const datetimeRenderer = (val: string | undefined | null) => {
  return val !== undefined && val !== null ? (
    isoToHumanString(val)
  ) : (
    <NotInterested />
  );
};

export const CloseSnackbarAction = (key: SnackbarKey) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      <Close />
    </IconButton>
  );
};
