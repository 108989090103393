import { MUIDataTableColumn } from "mui-datatables";

import { APIResponse } from ".";

import { departmentActionsRenderer } from "../helpers/renderers";

export type Department = {
  departmentID: number;
  name: string;
};

export type DepartmentResponse = APIResponse<Department>;

export const departmentColumns: MUIDataTableColumn[] = [
  { name: "departmentID", label: "ID" },
  { name: "name", label: "Name" },
  {
    name: "departmentID", // we need to pass id.
    label: "Actions",
    options: {
      customBodyRender: departmentActionsRenderer,
      sort: false,
    },
  },
];
