import DataTable from "../../components/DataTable";

import { Soft, softColumns } from "../../types/software";
import { getAllSoft } from "../../api/softwares";

const SoftTable = () => {
  return (
    <DataTable<Soft>
      title="Softwares"
      expandable={false}
      cols={softColumns}
      getData={getAllSoft}
    />
  );
};

export default SoftTable;
