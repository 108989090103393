import { MUIDataTableColumn } from "mui-datatables";

import { APIResponse } from ".";

import { datetimeRenderer, unitActionsRenderer } from "../helpers/renderers";

export type Unit = {
  unitID: number;
  location: string;
  locationID: number;
  originCountry: string;
  originCountryID: number;
  status: string;
  name: string;
  serialNumber: string;
  inventoryNumber: string;
  requirementNumber: string;
  comment: string;
  comingDate: string;
  debitDate: string | null;
};

export type UnitResponse = APIResponse<Unit>;

export const unitColumns: MUIDataTableColumn[] = [
  { name: "unitID", label: "ID" },
  { name: "location", label: "Location" },
  { name: "originCountry", label: "Country" },
  { name: "status", label: "Status" },
  { name: "name", label: "Name" },
  { name: "serialNumber", label: "Serial" },
  { name: "inventoryNumber", label: "Inventory" },
  { name: "requirementNumber", label: "Requirement" },
  { name: "comment", label: "Comment" },
  {
    name: "comingDate",
    label: "Coming date",
    options: {
      customBodyRender: datetimeRenderer,
    },
  },
  {
    name: "debitDate",
    label: "Debit date",
    options: {
      customBodyRender: datetimeRenderer,
    },
  },
  {
    name: "unitID", // we need to pass id.
    label: "Actions",
    options: {
      customBodyRender: unitActionsRenderer,
      sort: false,
    },
  },
];
