import { useState, useMemo, forwardRef, ReactElement } from "react";

import { Link, LinkProps, useLocation } from "react-router-dom";

import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Toolbar from "@material-ui/core/Toolbar";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Domain from "@material-ui/icons/Domain";

import { DistributiveOmit } from "@material-ui/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolbar: {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
    drawerContainer: {
      overflow: "auto",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

interface ListItemLinkProps {
  text: string;
  link: string;
  icon?: ReactElement;
  nested?: string;
}

const ListItemLink = ({ text, link, icon, nested }: ListItemLinkProps) => {
  const location = useLocation();

  const renderLink = useMemo(
    () =>
      forwardRef<any, DistributiveOmit<LinkProps, "to">>((itemProps, ref) => (
        <Link to={link} ref={ref} {...itemProps} />
      )),
    [link]
  );

  return (
    <li>
      <ListItem
        button
        component={renderLink}
        selected={link === location.pathname}
        className={nested}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={text} />
      </ListItem>
    </li>
  );
};

interface NestedListProps {
  title: string;
  items: ListItemLinkProps[];
  opened: boolean;
  closeDrawer: () => void;
}

const NestedList = ({ title, items, opened, closeDrawer }: NestedListProps) => {
  const classes = useStyles();

  const [open, setOpen] = useState(opened);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" onClick={closeDrawer} disablePadding>
          {items.map((item, idx) => (
            <ListItemLink
              key={idx}
              text={item.text}
              link={item.link}
              icon={item.icon}
              nested={classes.nested}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

interface DrawerContentProps {
  closeDrawer: () => void;
}

const DrawerContent = ({ closeDrawer }: DrawerContentProps) => {
  const classes = useStyles();

  const mainItems = [
    { text: "Arrivals", link: "/arrival/all" },
    { text: "Softwares", link: "/software/all" },
    { text: "Units", link: "/unit/all" },
  ];
  const additionalItems = [
    { text: "Departments", link: "/department/all", icon: <Domain /> },
  ];
  const actions = [{ text: "Add unit", link: "/unit/new" }];

  return (
    <>
      <Toolbar className={classes.toolbar} />
      <div className={classes.drawerContainer}>
        <List component="div" disablePadding>
          <NestedList
            title="Main"
            items={mainItems}
            opened={true}
            closeDrawer={closeDrawer}
          />
          <NestedList
            title="Additional"
            items={additionalItems}
            opened={true}
            closeDrawer={closeDrawer}
          />
          <NestedList
            title="Actions"
            items={actions}
            opened={false}
            closeDrawer={closeDrawer}
          />
        </List>
      </div>
    </>
  );
};

export default DrawerContent;
