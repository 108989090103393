import { useState, useEffect, ReactNode } from "react";

import { useRecoilState } from "recoil";
import { loadingState } from "../state/loading";

import { useSnackbar } from "notistack";

import Typography from "@material-ui/core/Typography";

import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUISortOptions,
} from "mui-datatables";

import { APIResponse } from "../types";

type DataTableProps<R> = {
  title: string;
  cols: MUIDataTableColumn[];
  getData: (
    page: number,
    size: number,
    search?: string
  ) => Promise<APIResponse<R>>;
  expandable?: boolean;
  renderExpandable?: (
    rowData: string[],
    rowMeta: {
      dataIndex: number;
      rowIndex: number;
    }
  ) => ReactNode;
};

const DataTable = <R,>({
  title,
  cols,
  getData,
  expandable,
  renderExpandable,
}: DataTableProps<R>) => {
  const setLoading = useRecoilState(loadingState)[1];
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(1);
  const [sortOrder, setSortOrder] = useState({} as MUISortOptions);
  const [data, setData] = useState<object[]>([["Loading data"]]);

  const search = async (searchText: string | null) => {
    setLoading({ variant: "indeterminate", value: 0, display: true });
    const resp = await getData(1, rowsPerPage, searchText ? searchText : "");
    setData((resp as any).data as unknown as object[]);
    setTotal((resp as any).recordsTotal);
    setLoading({ variant: "indeterminate", value: 0, display: false });
  };

  useEffect(() => {
    const get = async (
      page: number,
      rowsPerPage: number,
      sortOrder: MUISortOptions
    ) => {
      setLoading({ variant: "indeterminate", value: 0, display: true });

      let resp: APIResponse<R> = {} as APIResponse<R>;
      try {
        resp = await getData(page + 1, rowsPerPage, "");
      } catch (err: any) {
        const errText =
          typeof err.response.data.error === "string"
            ? err.response.data.error
            : "Some error occured";
        enqueueSnackbar(errText, {
          variant: "error",
        });
      }

      const sortField = sortOrder.name as keyof R;
      const sortDir = sortOrder.direction;
      if (sortField) {
        resp.data = resp.data.sort((a, b) => {
          if (a[sortField] < b[sortField]) {
            return 1 * (sortDir === "asc" ? -1 : 1);
          } else if (a[sortField] > b[sortField]) {
            return -1 * (sortDir === "asc" ? -1 : 1);
          } else {
            return 0;
          }
        });
      }

      setData(resp.data as unknown as object[]);
      setTotal(resp.recordsTotal);

      setLoading({ variant: "indeterminate", value: 0, display: false });
    };

    get(page, rowsPerPage, sortOrder);
  }, [page, rowsPerPage, sortOrder, getData, setLoading, enqueueSnackbar]);

  const options: MUIDataTableOptions = {
    filter: false,
    responsive: "vertical",
    tableBodyHeight: "auto",
    tableBodyMaxHeight: "calc(100vh - 64px - 24px - 24px - 64px - 53px)",
    selectableRows: "none",
    selectableRowsHeader: false,
    download: false,
    print: false,
    serverSide: true,
    count: total,
    page: page,
    pagination: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [10, 15, 20],
    sortOrder: sortOrder,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setPage(tableState.page);
          break;
        case "changeRowsPerPage":
          setRowsPerPage(tableState.rowsPerPage);
          break;
        case "sort":
          setSortOrder(tableState.sortOrder);
          break;
        case "search":
          search(tableState.searchText);
          break;
        default:
          console.log(action, "action not handled.");
      }
    },
    expandableRows: expandable,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: renderExpandable,
  };

  return (
    <MUIDataTable
      title={<Typography variant="h6">{title}</Typography>}
      data={data}
      columns={cols}
      options={options}
    />
  );
};

export default DataTable;
