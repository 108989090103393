import { AxiosResponse } from "axios";

import api from ".";

import { Soft, SoftResponse } from "../types/software";

export const getAllSoft = async (
  page: number,
  size: number,
  search?: string
) => {
  const resp = await api.get<SoftResponse, AxiosResponse<SoftResponse>>(
    `/soft?page=${page}&pageSize=${size}${search ? `&search=${search}` : ""}`
  );
  return resp.data;
};

export const getSoft = async (id: string) => {
  const resp = await api.get<Soft, AxiosResponse<Soft>>(`/soft/${id}`);
  return resp.data;
};

export const addSoft = async (s: Soft) => {
  await api.post("/soft", {
    soft: s,
  });
};

export const editSoft = async (s: Soft) => {
  await api.put(`/soft/${s.softID}`, {
    soft: s,
  });
};

export const deleteSoft = async (id: string) => {
  await api.delete(`/soft/${id}`);
};
