import { AxiosResponse } from "axios";

import api from ".";

import { Arrival, ArrivalResponse } from "../types/arrival";

export const getArrivals = async (
  page: number,
  size: number,
  search?: string
) => {
  const resp = await api.get<ArrivalResponse, AxiosResponse<ArrivalResponse>>(
    `/arrivals?page=${page}&pageSize=${size}${
      search ? `&search=${search}` : ""
    }`
  );
  return resp.data;
};

export const getArrival = async (id: string) => {
  const resp = await api.get<Arrival, AxiosResponse<Arrival>>(
    `/arrivals/${id}`
  );
  return resp.data;
};

export const addArrival = async (a: Arrival) => {
  await api.post("/arrivals", {
    arrival: a,
  });
};

export const editArrival = async (a: Arrival) => {
  await api.put(`/arrivals/${a.arrivalID}`, {
    arrival: a,
  });
};

export const deleteArrival = async (id: string) => {
  await api.delete(`/arrivals/${id}`);
};
