import { atom, selector } from "recoil";

const checkToken = () => {
  const token = localStorage.getItem("token");
  return (
    !!token &&
    JSON.parse(Buffer.from(token.split(".")[1], "base64").toString()).exp >
      new Date().getTime() / 1000
  );
};

export const userState = atom({
  key: "userState",
  default: {
    username: "",
    logoutAt: -1,
  },
});

export const loggedInState = selector({
  key: "loggedInState",
  get: () => {
    return checkToken();
  },
});
