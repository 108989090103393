import { useState, useEffect } from "react";

import { useParams, useHistory } from "react-router-dom";

import { useRecoilState } from "recoil";
import { loadingState } from "../../state/loading";

import { useSnackbar } from "notistack";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";

import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";

import FormLayout, { FormProps, useStyles } from "../layouts/FormLayout";
import AutoComplete from "../../components/AutoComplete";

import { Unit } from "../../types/unit";
import {
  getUnit,
  addUnit,
  editUnit,
  deleteUnit,
  getLocations,
  getUnitLocation,
  getCountries,
  getUnitOrigin,
} from "../../api/units";

import UnitSchema from "../../validations/UnitSchema";

interface Values {
  unit: Unit;
  location: { locationID: number; name: string };
  country: { originID: number; name: string };
}

const UnitForm = ({ action }: FormProps) => {
  const classes = useStyles();

  const { id } = useParams<Record<string, string | undefined>>();
  const history = useHistory();
  const setLoading = useRecoilState(loadingState)[1];
  const { enqueueSnackbar } = useSnackbar();

  const [unit, setUnit] = useState({
    status: "",
    name: "",
    serialNumber: "",
    inventoryNumber: "",
    requirementNumber: "",
    comment: "",
  } as Unit);
  const [location, setLocation] = useState(
    {} as { locationID: number; name: string }
  );
  const [country, setCountry] = useState(
    {} as { originID: number; name: string }
  );

  const title = action === "add" ? "New Unit" : unit.name;
  const readOnly = action === "view" || action === "delete";

  useEffect(() => {
    const get = async (id: string) => {
      try {
        setLoading({ variant: "indeterminate", value: 0, display: true });

        const data = await getUnit(id);
        if (data) {
          setUnit(data);
          const l = await getUnitLocation(data.locationID);
          setLocation(l);
          const o = await getUnitOrigin(data.originCountryID);
          setCountry(o);
        }
      } catch (err: any) {
        const errText =
          typeof err.response.data.error === "string"
            ? err.response.data.error
            : "Some error occured";
        enqueueSnackbar(errText, {
          variant: "error",
        });
      } finally {
        setLoading({ variant: "indeterminate", value: 0, display: false });
      }
    };

    if (id) {
      get(id);
    }
  }, [id, setLoading, enqueueSnackbar]);

  return (
    <FormLayout title={title}>
      <Formik
        initialValues={{
          unit,
          location,
          country,
        }}
        validationSchema={UnitSchema}
        onSubmit={async (values: Values, { setSubmitting }) => {
          console.log(values);
          try {
            switch (action) {
              case "add": {
                values.unit.locationID = values.location.locationID;
                values.unit.originCountryID = values.country.originID;
                await addUnit(values.unit);
                enqueueSnackbar(
                  `${values.unit.name} unit successfully added.`,
                  {
                    variant: "success",
                  }
                );
                break;
              }
              case "edit": {
                values.unit.locationID = values.location.locationID;
                values.unit.originCountryID = values.country.originID;
                await editUnit(values.unit);
                enqueueSnackbar(
                  `${values.unit.name} unit successfully updated.`,
                  {
                    variant: "success",
                  }
                );
                break;
              }
              case "delete": {
                await deleteUnit(values.unit.unitID);
                enqueueSnackbar(
                  `${values.unit.name} unit successfully deleted.`,
                  {
                    variant: "success",
                  }
                );
                break;
              }
              default:
                break;
            }
            history.push("/unit/all");
          } catch (err: any) {
            const errText =
              typeof err.response.data.error === "string"
                ? err.response.data.error
                : "Some error occured";
            enqueueSnackbar(errText, {
              variant: "error",
            });
          } finally {
            setSubmitting(false);
          }
        }}
        enableReinitialize={true}
      >
        {({ isSubmitting, touched, errors }) => (
          <Form className={classes.form} autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="unit.name"
                    label="Name"
                    type="text"
                    fullWidth
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    select
                    name="unit.status"
                    label="Status"
                    type="text"
                    fullWidth
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  >
                    {[
                      { value: "in_stock", label: "In stock" },
                      { value: "transferred", label: "Transferred" },
                      { value: "debit", label: "Debit" },
                      { value: "unknown", label: "Unknown" },
                    ].map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="unit.serialNumber"
                    label="Serial"
                    type="text"
                    fullWidth
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="unit.inventoryNumber"
                    label="Inventory"
                    type="text"
                    fullWidth
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="unit.requirementNumber"
                    label="Requirement"
                    type="text"
                    fullWidth
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="unit.comment"
                    label="Comment"
                    type="text"
                    fullWidth
                    InputProps={{
                      readOnly: readOnly,
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12}>
                  <AutoComplete<{ locationID: number; name: string }>
                    name="location"
                    label="Location"
                    value={location}
                    multiple={false}
                    readOnly={readOnly}
                    getOptions={getLocations}
                    getOptionSelected={(location, value) =>
                      location.locationID === value.locationID
                    }
                    getOptionLabel={(location) =>
                      location.name ? location.name : ""
                    }
                    touched={touched}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutoComplete<{ originID: number; name: string }>
                    name="country"
                    label="Origin country"
                    value={country}
                    multiple={false}
                    readOnly={readOnly}
                    getOptions={getCountries}
                    getOptionSelected={(originCountry, value) =>
                      originCountry.originID === value.originID
                    }
                    getOptionLabel={(originCountry) =>
                      originCountry.name ? originCountry.name : ""
                    }
                    touched={touched}
                    errors={errors}
                  />
                </Grid>
              </Grid>
            </Grid>
            <br />
            {action !== "view" && (
              <Button
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                type="submit"
              >
                {action}
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </FormLayout>
  );
};

export default UnitForm;
