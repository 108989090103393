import { AxiosResponse } from "axios";

import api from ".";

import { Department, DepartmentResponse } from "../types/department";

export const getDepartments = async (
  page: number,
  size: number,
  search?: string
) => {
  const resp = await api.get<
    DepartmentResponse,
    AxiosResponse<DepartmentResponse>
  >(
    `/departments?page=${page}&pageSize=${size}${
      search ? `&search=${search}` : ""
    }`
  );
  return resp.data;
};

export const getDepartment = async (id: string) => {
  const resp = await api.get<Department, AxiosResponse<Department>>(
    `/departments/${id}`
  );
  return resp.data;
};

export const addDepartment = async (d: Department) => {
  await api.post("/departments", {
    department: d,
  });
};

export const editDepartment = async (d: Department) => {
  await api.put(`/departments/${d.departmentID}`, {
    department: d,
  });
};

export const deleteDepartment = async (id: string) => {
  await api.delete(`/departments/${id}`);
};
