import DataTable from "../../components/DataTable";

import { Unit, unitColumns } from "../../types/unit";
import { getAllUnits } from "../../api/units";

const UnitTable = () => {
  return (
    <DataTable<Unit>
      title="Units"
      expandable={false}
      cols={unitColumns}
      getData={getAllUnits}
    />
  );
};

export default UnitTable;
